<template>
	<div class="nft_wait">
		<div class="container">
			<div class="nft_wait__content">
				<div class="nft_wait__pretitle">
					<span class="dotLive"></span>the private sale is live
				</div>
				<div class="nft_wait__title">
					Check to see if<br />
					you’re eligible for<br />
					<span class="nft_wait__title_primary">the early access</span>
				</div>
				<div class="nft_wait__buttons">
					<button
						type="button"
						class="nft_wait__button vrlps-trigger"
						@click.prevent="handleWalletClick"
					>
						Connect wallet
					</button>
					<a
						href="https://cointelegraph.com/historical/"
						target="_blank"
						class="nft_wait__button nft_wait__button--empty"
					>
						<span class="nft_wait__button_extra_text">Learn more</span>
					</a>
				</div>
			</div>
			<div class="nft_images_wrapper">
				<div class="nft_images">
					<div class="nft_images__col">
						<div
							class="nft_images__item"
							v-for="(image, index) in imagesLeft"
							:key="index"
						>
							<LazyImage :img="image" alt="" />
						</div>
						<div
							class="nft_images__item"
							v-for="(image, index) in imagesLeft"
							:key="index"
						>
							<LazyImage :img="image" alt="" />
						</div>
					</div>
					<div class="nft_images__col">
						<div
							class="nft_images__item"
							v-for="(image, index) in imagesRight"
							:key="index"
						>
							<LazyImage :img="image" alt="" />
						</div>
						<div
							class="nft_images__item"
							v-for="(image, index) in imagesRight"
							:key="index"
						>
							<LazyImage :img="image" alt="" />
						</div>
					</div>
				</div>
				<img
					:src="require('@/assets/images/nft-images/shadow.svg')"
					class="nft_images__shadow"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { LazyImage } from 'mintmade-main'
import { useMeta } from 'vue-meta'
export default {
	name: "NftWaitlist",
	setup () {
		useMeta({ 
			title: 'Historical NFT Collection by Cointelegraph',
			description: 'Search for iconic articles or hunt for hot stories early to mint them into digital collectibles.'
		})
	},
	data() {
		return {
			imagesLeft: [
				require("@/assets/images/nft-images/1.png?placeholder"),
				require("@/assets/images/nft-images/2.png?placeholder"),
				require("@/assets/images/nft-images/3.png?placeholder"),
				require("@/assets/images/nft-images/4.png?placeholder"),
				require("@/assets/images/nft-images/5.png?placeholder"),
				require("@/assets/images/nft-images/6.png?placeholder"),
				require("@/assets/images/nft-images/7.png?placeholder"),
				require("@/assets/images/nft-images/8.png?placeholder"),
				require("@/assets/images/nft-images/9.png?placeholder"),
			],
			imagesRight: [
				require("@/assets/images/nft-images/10.png?placeholder"),
				require("@/assets/images/nft-images/11.png?placeholder"),
				require("@/assets/images/nft-images/12.png?placeholder"),
				require("@/assets/images/nft-images/13.png?placeholder"),
				require("@/assets/images/nft-images/14.png?placeholder"),
				require("@/assets/images/nft-images/15.png?placeholder"),
				require("@/assets/images/nft-images/16.png?placeholder"),
				require("@/assets/images/nft-images/17.png?placeholder"),
				require("@/assets/images/nft-images/18.png?placeholder"),
			],
		};
	},
	methods: {
        handleWalletClick() {
            window.amplitude.getInstance().logEvent('click connect wallet')
            this.$store.dispatch("walletClick")
        }
    },
	mounted: () => {
		// Lazyload crutch
		setInterval(() => {
			window.dispatchEvent(new Event('scroll'));
		}, 5000);
	},
	components: {
		LazyImage
	}
};
</script>

<style lang="scss">
.dotLive{
	display: inline-block;
    position: relative;
    background-color: #2FF372;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    z-index: 1;
    margin-right: 7px;
	margin-bottom: -2px;
    &:before{
		content: '';
		display: block;
		position: absolute;
		background-color: rgba(#2FF372,.6);
		width: 100%;
		height: 100%;
		border-radius: 50%;
		animation: live 2s ease-in-out infinite;
		z-index: -1;
    }
}
@keyframes live {
	0% {
		transform: scale(1,1);
	}
	100% {
		transform: scale(3.5,3.5);
		background-color: rgba(#2FF372,0);
	}
}

.whiteListPage {
	position: relative;
	background: #000;

	.footer__social_col,
	.footer__terms_col {
		display: none;

		@media (max-width: 991px) {
			display: flex;
		}
	}
}
.nft_wait {
	background-color: #000000;
	background-size: cover;
	background-position: left top;
	background-image: url("~@/assets/images/waitlistBg.svg");
	color: #fff;
	position: relative;
	min-height: 750px;
	height: var(--app-height);
	padding-bottom: 250px;
	padding-top: 250px;
	@media (min-width: 992px) {
		display: flex;
	}
	.container {
		@media (min-width: 992px) {
			width: 100%;
			display: flex;
			align-items: center
		}
	}
	@media (max-width: 991px) {
		height: auto;
		overflow: hidden;
		padding-bottom: 440px;
		padding-top: 0;
		background-image: none;
	}
}
.nft_wait__content {
	padding-top: 0;
	max-width: 613px;
	@media (max-width: 991px) {
		max-width: none;
		padding-top: 467px;
		z-index: 5;
		position: relative;
	}
}
.nft_wait__pretitle {
	text-transform: uppercase;
	color: $base-color;
	font-weight: 600;
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 20px;
	@media (max-width: 991px) {
		margin-bottom: 12px;
	}
}
.nft_wait__title {
	font-weight: 800;
	font-size: 52px;
	line-height: 56px;
	margin-bottom: 40px;
	@media (max-width: 991px) {
		font-size: 34px;
		line-height: 39px;
		margin-bottom: 32px;
	}
}
.nft_wait__title_primary {
	color: $base-color;
}
.nft_wait__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: 24px;
	line-height: 24px;
	margin-bottom: 48px;
	@media (max-width: 991px) {
		font-size: 18px;
		line-height: 18px;
	}
	li {
		display: flex;
		margin-bottom: 13px;
		@media (max-width: 991px) {
			margin-bottom: 5px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.nft_wait__list_number {
	font-weight: 600;
	width: 17px;
	margin-right: 9px;
	color: $base-color;
}
.nft_wait__buttons {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
}
.nft_wait__button {
	text-decoration: none;
	height: 52px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	background: $base-color;
	color: #000000;
	margin-right: 17px;
	cursor: pointer;
	font-weight: 500;
	transition: background 0.3s;
	font-size: 16px;
	line-height: 24px;
	font-family: "Poppins";
	border: 0;
	min-width: 165px;
	@media (max-width: 767px) {
		flex-grow: 1;
	}
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		background: #fbd26e;
	}
	img {
		margin-right: 11px;
		@media (max-width: 767px) {
			margin-right: 0;
		}
	}
}
.nft_wait__button--empty {
	background: transparent;
	border: 1px solid $base-color;
	padding: 0 14px;
	color: $base-color;
	position: relative;
	overflow: hidden;
	min-width: 170px;
	@media (max-width: 767px) {
		padding: 0 16px;
	}
	&:hover {
		background: transparent;
		&:after {
			transform: translate(0, 0);
		}
		&:before {
			opacity: 1;
		}
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			110.65deg,
			rgba(250, 191, 44, 0.16) 15.85%,
			rgba(250, 191, 44, 0) 60.23%
		);
		transform: translate(-100%, 0);
		transition: transform 0.3s;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 2px solid $base-color;
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.3s;
	}
}
.nft_wait__check_list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 24px;
		color: #8e8e8e;
		transition: color 0.3s;
		@media (max-width: 991px) {
			color: #fff;
			svg path {
				fill: $base-color;
			}
		}
		svg {
			margin-right: 6px;
			path {
				transition: fill 0.3s;
			}
		}
	}

	&--hovered {
		li {
			color: #fff;
			svg path {
				fill: $base-color;
			}
		}
	}
}

.nft_images_wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	width: 49.4%;
	max-width: 711px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	@media (max-width: 991px) {
		max-width: none;
		width: 152%;
		top: -170px;
		right: -161px;
		bottom: auto;
		height: 786px;
		&:after {
			content: "";
			position: absolute;
			width: 100vw;
			padding-top: 60.3%;
			bottom: -1px;
			right: 161px;
			background: linear-gradient(
				10deg,
				rgba(#000000, 1) 16.98%,
				rgba(#000000, 0) 58.21%
			);
		}
	}
}
.nft_images {
	display: flex;
	align-items: flex-start;
	width: 100%;
	@media (max-width: 991px) {
		justify-content: space-between;
		transform: rotate(29deg);
	}
}
.nft_images__col {
	width: 56%;
	flex-shrink: 0;
	@media (max-width: 991px) {
		width: calc(50% - 5px);
	}
	&:nth-child(1) {
		animation: nftImageAnimation 85s linear 0s infinite;
		margin-right: 20px;
		@media (max-width: 991px) {
			margin-right: 10px;
		}
	}
	&:nth-child(2) {
		animation: nftImageAnimation 85s linear 0s infinite reverse;
	}
}
.nft_images__item {
	position: relative;
	padding: 1px;
	border-radius: 23px;
	padding-top: 133%;
	overflow: hidden;
	background: linear-gradient(
		0deg,
		rgba(250, 191, 44, 0) 0%,
		rgba(250, 191, 44, 1) 100%
	);
	margin-bottom: 16px;
	&:last-child {
		margin-bottom: 0;
	}
	img {
		position: absolute;
		left: 1px;
		top: 1px;
		right: 1px;
		width: 100%;
		max-width: calc(100% - 2px);
		border-radius: 23px;
	}
}
.nft_images__shadow {
	pointer-events: none;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: calc(100% + 153px);
	left: -153px;
	@media (max-width: 991px) {
		display: none;
	}
}

.wait_video_popup {
	.m_popup__content {
		padding: 0;
		max-width: 993px;
		@media (max-width: 991px) {
			height: 100%;
		}
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.wait_video_popup__container {
	position: relative;
	padding-top: 55.8%;
	@media (max-width: 991px) {
		padding-top: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.wait_video_popup__cross {
	position: absolute;
	width: 34px;
	height: 34px;
	background: #fff;
	top: 16px;
	right: 16px;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
}

@keyframes nftImageAnimation {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(0, -50.2%);
	}
}
@media (max-width: 991px){
	.nft_wait__button{
		min-width: auto;
	}
	.nft_wait__button--empty{
		min-width: auto;
	}
}
</style>
