// @ts-nocheck
import { createStore } from "vuex";
import MainStore from "../../node_modules/mintmade-main/src/store/MainStore.ts"

const store = createStore({
  modules: {
    MainStore
  },
});

export default store;