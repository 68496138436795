import { createRouter, createWebHistory } from "vue-router";
import store from '../store'

const routes = [
  {
    path: "/",
    name: "Home", 
    props: true,
    component: function() {
      return import(/* webpackChunkName: "home" */ "../views/NftPage.vue")
    }
  },
  {
    path: "/terms/",
    name: "Terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/Terms.vue"),
  },
  {
    path: "/cointelegraph/leaderboard/",
    name: "leaderboard",
    component: () => import(/* webpackChunkName: "leaderboard" */ "../views/Leaderboard.vue"),
  },
  {
    path: "/cointelegraph/challenges/",
    name: "challenges",
    component: () => import(/* webpackChunkName: "challenges" */ "../views/Challenges.vue"),
  },
  {
    path: "/cointelegraph/mediaverse/",
    name: "mediaverse",
    component: () => import(/* webpackChunkName: "mediaverse" */ "../views/Mediaverse.vue"),
  },
  {
    path: "/cointelegraph/governance/",
    name: "governance",
    component: () => import(/* webpackChunkName: "governance" */ "../views/Governance.vue"),
  },
  {
    path: "/collection/:address",
    name: "collection",
    component: () => import(/* webpackChunkName: "my-collection" */ "../views/MyCollection.vue"),
  },
  {
    path: "/collection/",
    name: "collection2",
    component: () => import(/* webpackChunkName: "my-collection2" */ "../views/MyCollection.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  store.commit('CHANGE_PAGE_LOAD', false);
  next()
})

router.afterEach(() => {
  store.commit('CHANGE_PAGE_LOAD', true);
})

export default router;
